module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"adarq.net","short_name":"adarq","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"/opt/build/repo/src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9a8b281f6040e287378d4fc96ffa48c4"},
    },{
      plugin: require('../node_modules/@jgantunes/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-WN4HBT6W9M"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0,"storage":"none","client_storage":"none"},"gtagConsent":{"ad_storage":"denied","analytics_storage":"granted","functionality_storage":"denied","personalization_storage":"denied","security_storage":"denied"},"pluginConfig":{"head":true,"respectDNT":true}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
